<template>
  <div id="view-service-subscription">
    <v-container>
      <div class="d-flex align-center space-between mt-4">
        <h1>Subscription Details</h1>
        <AdsButton
          class="ml-auto"
          @click="cancel"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </AdsButton>
      </div>
      <ServiceSubscriptionForm
        :initial-service-subscription="serviceSubscription"
        @save="updateServiceSubscription"
        v-if="serviceSubscription?.subscriptionId"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert, AdsButton } from '@nswdoe/doe-ui-core'
import ServiceSubscriptionForm from '@/views/Subscriptions/form'
import { DEFAULT_SERVICE_SUBSCRIPTION } from '@/constants/initial'

export default {
  name: 'UpdateServiceSubscription',
  title: 'Update Service Subscription - SAIS Operation UI Portal',
  components: {
    Alert,
    AdsButton,
    ServiceSubscriptionForm,
  },
  data() {
    return {
      serviceSubscription: DEFAULT_SERVICE_SUBSCRIPTION,
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
      },
    }
  },

  created() {
    if (
      !this.$store.state.moduleSchools.schools ||
      this.$store.state.moduleSchools.schools.length === 0
    ) {
      this.$store.dispatch('moduleSchools/fetchSchools').finally(() => {})
    }

    this.$store
      .dispatch('moduleSubscriptions/fetchSubscription', {
        id: this.$route.params.id,
        supplierId: this.$route.query.supplierId,
      })
      .finally(() => {
        const serviceSubscription = this.$store.state.moduleSubscriptions.selectedSubscription
        const selectedSchool = this.$store.state.moduleSchools.schools.find(
          (school) => school.code === serviceSubscription.schoolCode
        )

        const subscriber = selectedSchool?.name
        const originalEndDate = serviceSubscription.endDate

        this.serviceSubscription = {
          ...serviceSubscription,
          subscriber,
          originalEndDate,
        }
      })
  },

  methods: {
    cancel() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push('/serviceregistry/subscriptions')
      }
    },
    async updateServiceSubscription(item) {
      const response = await this.$store.dispatch('moduleSubscriptions/putSubscription', item)

      this.saveClicked = {
        isClicked: true,
        isSuccess: response.isError ? false : true,
        type: response.isError ? 'error' : 'success',
        message: response.isError
          ? response.errorMessage
          : 'The changes have been updated successfully',
      }

      if (!response.isError) {
        this.cancel()
      }
    },
  },
}
</script>
