<template>
  <v-form ref="serviceSubscriptionForm">
    <v-card class="pa-6 my-8">
      <AdsTextArea
        v-model="justificationValue"
        label="Order Number/ Other"
        maxlength="200"
        counter="200"
        :rules="[validators.required]"
        :disabled="true"
      />
      <AdsTextField
        v-model="serviceSubscription.supplierName"
        label="Supplier"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsTextField
        v-model="serviceSubscription.productName"
        label="Supplier Product"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsSelect
        v-model="serviceSubscription.isFinancial"
        :items="[
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ]"
        outlined
        label="Finance Exclusive"
        :disabled="true"
      />
      <AdsSelect
        :multiple="true"
        v-model="serviceSubscription.services"
        :items="serviceSubscription.services"
        class="mb-0"
        outlined
        label="Subcategories"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsTextField
        v-model="serviceSubscription.subscriber"
        label="Subscriber"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsTextField
        v-model="serviceSubscription.status"
        label="Status"
        :disabled="true"
        :rules="[validators.required]"
      />
      <DatePicker
        v-model="serviceSubscription.startDate"
        label="From"
        :disabled="true"
        :rules="[validators.required]"
      />
      <DatePicker
        v-model="serviceSubscription.endDate"
        label="To"
        :rules="endDateRules"
        @input="endDateChanged"
        :readonly="isReadOnlyUser"
      />
      <AdsTextArea
        v-model="serviceSubscription.comments"
        label="Admin Comments"
        maxlength="5000"
        counter="5000"
        :rules="commentsRules"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <AdsButton
      :primary="true"
      buttonText="Save"
      class="mr-4"
      :disabled="isReadOnlyUser"
      :readonly="isReadOnlyUser"
      @click="save"
    />
    <AdsButton
      :primary="true"
      buttonText="Cancel"
      @click="cancel"
    />
  </v-form>
</template>
<script>
import { AdsTextField, AdsTextArea, DatePicker, AdsButton, AdsSelect } from '@nswdoe/doe-ui-core'
import { DEFAULT_SERVICE_SUBSCRIPTION } from '@/constants/initial'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'
import dayjs from 'dayjs'
import { get } from 'lodash'

export default {
  name: 'ViewServiceSubscription',
  components: {
    AdsTextField,
    AdsSelect,
    AdsTextArea,
    DatePicker,
    AdsButton,
  },
  props: {
    initialServiceSubscription: {
      type: Object,
      default: DEFAULT_SERVICE_SUBSCRIPTION,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    justificationValue() {
      return get(this, 'serviceSubscription.justification.value', '')
    },
  },
  data() {
    return {
      serviceSubscription: this.initialServiceSubscription,
      errors: [],
      commentsRules: [],
      endDateRules: [],
      showErrors: false,
      validators: {
        required: validators.required,
        alpha: validators.alpha,
        phone: validators.phone,
        email: validators.email,
        alphaNumeric: validators.alphaNumeric,
        isValidURL: validators.isValidURL,
      },
    }
  },
  methods: {
    endDateChanged() {
      if (this.serviceSubscription?.endDate) {
        let endDate
        if (this.serviceSubscription.endDate.indexOf('-') !== -1) {
          endDate = dayjs(this.serviceSubscription.endDate, 'YYYY-MM-DD')
        } else {
          endDate = dayjs(this.serviceSubscription.endDate, 'DD/MM/YYYY')
        }
        const startDate = dayjs(this.serviceSubscription.startDate, 'YYYY-MM-DD')
        const diff = dayjs(endDate).diff(startDate)
        if (diff === 0) {
          this.commentsRules = []
        } else {
          this.commentsRules = [validators.required]
          if (diff > 0) {
            this.endDateRules = [validators.required]
          } else {
            this.endDateRules = [
              validators.required,
              () => 'The end Date can not before the start date.',
            ]
          }
        }
      }
    },
    cancel() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push('/serviceregistry/subscriptions')
      }
    },
    save() {
      this.validate()
      this.errors = getFormErrors(this.$refs.serviceSubscriptionForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        // No validation errors
        //form to api request model mapping
        let serviceSubscription = {
          subscriptionId: this.serviceSubscription.subscriptionId,
          supplierId: this.serviceSubscription.supplierId,
          endDate: dayjs(this.serviceSubscription.endDate).format('DD/MM/YYYY'),
          comments: this.serviceSubscription.comments,
        }
        this.$emit('save', serviceSubscription)
      }
    },
    validate() {
      if (this.$refs.serviceSubscriptionForm) {
        this.$refs.serviceSubscriptionForm.validate()
      }
    },
  },
}
</script>
